import { mapGetters, mapActions, mapMutations } from "vuex"
import { getHeadersForTable } from "@/utils/table"
import { TABLE_NAMES, FIELD_TYPES, MAX_CHARACTER_LIMIT } from "@/constants"
import ThemisInput from "@/components/shared/input"
import { ISSUE_FORM } from "@/constants/bread-crumbs/issue-form"
import { PAGE_TITLE_WITHOUT_TRANSLATION } from "@/constants/page-titles"

export default {
  name      : "IssueForm",
  components: {
    ThemisInput
  },
  data() {
    return {
      localIssueForm              : undefined,
      canUpdateFormName           : false,
      fieldsToBeAdded             : [],
      showAddIssueFormFieldsDialog: false,
      fieldsAreDraggable          : false,
      draggingFormTemplateField   : undefined,
      dragEnterFormTemplateField  : undefined,
      issueFormNameCharacterLimit : MAX_CHARACTER_LIMIT.ISSUE_FORM_NAME
    }
  },
  methods: {
    ...mapActions({
      updateIssueForm                : "formTemplates/updateFormTemplate",
      addFormTemplateConfigurations  : "formTemplateConfigurations/addFormTemplateConfigurations",
      updateFormTemplateConfiguration: "formTemplateConfigurations/updateFormTemplateConfiguration",
      notify                         : "shared/notify"
    }),
    ...mapMutations({
      resetIssueFormUpdateError: "formTemplates/resetFormTemplateUpdateError",
      setBreadcrumbs           : "shared/setBreadcrumbs",
      setPageTitle             : "shared/setPageTitle"
    }),
    getFieldTypeAndIcon(field) {
      const fieldType = Object.values(FIELD_TYPES).find(type => type.value === field.type)

      return {
        fieldType: this.$t(fieldType.name, {
          name: this.optionListsMap[field.optionListId] ? `: ${this.optionListsMap[field.optionListId].name}` : ""
        }),
        fieldTypeIcon: fieldType.icon
      }
    },
    handleFormNameInputOnBlurEvent(onBlur){
      onBlur()
      this.verifyAndUpdateIssueForm()
    },
    handleIssueFormNameInputOnEnter() {
      this.$refs.issue_form_name.blur()
    },
    verifyAndUpdateIssueForm(){
      if (this.isIssueFormNameChanged) {
        if (this.localIssueForm.name && this.localIssueForm.name.length <= this.issueFormNameCharacterLimit) {
          this.updateIssueForm({
            id  : this.localIssueForm.id,
            name: this.localIssueForm.name
          })
        }
      }
    },
    async addFormTemplateConfigurationsHandler() {
      const formTemplateConfigurationsPayload = this.fieldsToBeAdded.map(field => ({
        formTemplateId: this.issueFormId,
        fieldId       : field.id
      }))
      this.addFormTemplateConfigurations(formTemplateConfigurationsPayload)
    },
    getClassForFieldRow(item) {
      let classForFieldRow = ""
      if (item.id === this.selectedFormTemplateConfiguration?.id) {
        classForFieldRow += "blue lighten-5"
      }
      if (item.id === this.dragEnterFormTemplateField?.id && item.id !== this.draggingFormTemplateField.id) {
        classForFieldRow += "drop-row"
      }
      return classForFieldRow
    },
    onFieldDragStart(formTemplateField) {
      this.draggingFormTemplateField  = formTemplateField
      this.dragEnterFormTemplateField = this.draggingFormTemplateField
    },
    onFieldDragEnter(formTemplateField) {
      this.dragEnterFormTemplateField = formTemplateField
    },
    onFieldDragOver(event) {
      event.preventDefault()
    },
    onFieldDragEnd() {
      if (this.draggingFormTemplateField.id !== this.dragEnterFormTemplateField.id) {
        const isFormTemplateFieldDraggedUp =
        !!(this.draggingFormTemplateField.sortingOrder - this.dragEnterFormTemplateField.sortingOrder)

        const sortingOrder = isFormTemplateFieldDraggedUp ?
          this.dragEnterFormTemplateField.sortingOrder : this.dragEnterFormTemplateField.sortingOrder - 1

        this.updateFormTemplateConfiguration({
          id: this.draggingFormTemplateField.id,
          sortingOrder
        })
      }
    },
    handleClickOnRow(item) {
      if ((this.$route.name === "issue-form" ||
        this.$route.name === "issue-form-field-configuration") &&
        +this.$route.params?.configurationId !== item.id) {
        this.$router.push({
          name  : "issue-form-field-configuration",
          params: {
            id             : this.issueFormId,
            configurationId: item.id
          }
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      formTemplates                       : "formTemplates/formTemplates",
      isLoadingFormTemplates              : "formTemplates/isLoadingFormTemplates",
      isUpdatingIssueFormName             : "formTemplates/isUpdatingFormTemplateName",
      issueFormNameUpdateError            : "formTemplates/formTemplateNameUpdateError",
      fieldsV2                            : "fields/fieldsV2",
      isLoadingFields                     : "fields/isLoadingFieldsV2",
      optionLists                         : "optionLists/optionLists",
      isLoadingOptionLists                : "optionLists/isLoadingOptionLists",
      formTemplateConfigurations          : "formTemplateConfigurations/formTemplateConfigurations",
      isAddingFormTemplateConfigurations  : "formTemplateConfigurations/isAddingFormTemplateConfigurations",
      issueFormTemplateConfigurationsAdded: "formTemplateConfigurations/formTemplateConfigurationsAdded",
      isUpdatingSortingOrder              : "formTemplateConfigurations/isUpdatingSortingOrder",
      isSortingOrderUpdated               : "formTemplateConfigurations/isSortingOrderUpdated",
      isFormTemplateNameUpdated           : "formTemplates/isFormTemplateNameUpdated"
    }),
    issueFormId() {
      return +this.$route.params.id
    },
    issueForm() {
      return this.formTemplates.find(formTemplate => formTemplate.id === this.issueFormId)
    },
    issueFormFieldsTableDetailsLoading() {
      return this.isLoadingFormTemplates
         || this.isLoadingFields
         || this.isLoadingOptionLists
         || this.isUpdatingSortingOrder
    },
    headersForIssueFormFieldsOverviewTable(){
      return getHeadersForTable(TABLE_NAMES.ISSUE_FORM_FIELDS, this.$t.bind(this))
    },
    headersForFieldsAddTable() {
      return getHeadersForTable(TABLE_NAMES.ISSUE_FORM_TEMPLATE_ADD_FIELDS, this.$t.bind(this))
    },
    fieldsForIssueFormOverviewTable() {
      return this.formTemplateConfigurations.filter(formTemplateConfiguration =>
        formTemplateConfiguration.formTemplateId === this.issueFormId
      ).sort((firstFormTemplateConfiguration, secondFormTemplateConfigurations) =>
        firstFormTemplateConfiguration.sortingOrder - secondFormTemplateConfigurations.sortingOrder
      ).map(formTemplateConfiguration => {
        const field = this.fieldsMap[formTemplateConfiguration.fieldId]
        let fieldId
        let fieldSystemName
        let fieldType
        let fieldTypeIcon
        if (field) {
          ({ fieldType, fieldTypeIcon } = this.getFieldTypeAndIcon(field))
          fieldId                      = field.id
          fieldSystemName              = field.systemName
        }
        return {
          id          : formTemplateConfiguration.id,
          fieldId,
          sortingOrder: formTemplateConfiguration.sortingOrder,
          fieldSystemName,
          fieldType,
          fieldTypeIcon
        }
      })
    },
    fieldsMap() {
      const fieldsMap = new Object()
      for (const field of this.fieldsV2) {
        fieldsMap[field.id] = field
      }
      return fieldsMap
    },
    optionListsMap() {
      const optionListsMap = new Object()
      for (const optionList of this.optionLists) {
        optionListsMap[optionList.id] = optionList
      }
      return optionListsMap
    },
    isIssueFormNameChanged() {
      return this.issueForm?.name !== this.localIssueForm?.name
    },
    isIssueFormNameDuplicate() {
      return this.issueFormNameUpdateError?.type === "duplicate"
    },
    issueFormTemplateFieldIds() {
      return this.fieldsForIssueFormOverviewTable.map(formTemplateField =>
        formTemplateField.fieldId
      )
    },
    fieldsForIssueFormTemplateFieldsAddTable() {
      return this.fieldsV2.filter(field =>
        !this.issueFormTemplateFieldIds.includes(field.id)
      ).map(field => {
        return {
          id        : field.id,
          systemName: field.systemName,
          ...this.getFieldTypeAndIcon(field)
        }
      })
    },
    calculateHeightForAddTable() {
      const dataRowHeight      = 48
      const maxAvailableHeight = window.innerHeight - 430
      const maxDataRows        = Math.floor((maxAvailableHeight / dataRowHeight) - 1)
      let heightOfTable        = dataRowHeight // initialize with height for header

      if (this.fieldsForIssueFormTemplateFieldsAddTable.length > maxDataRows) {
        heightOfTable += maxDataRows * dataRowHeight
      } else if (!this.fieldsForIssueFormTemplateFieldsAddTable.length) {
        heightOfTable += dataRowHeight // one row for "no data available"
      } else {
        heightOfTable += this.fieldsForIssueFormTemplateFieldsAddTable.length * dataRowHeight
      }

      return heightOfTable
    },
    selectedFormTemplateConfiguration() {
      if (this.$route.params.configurationId) {
        return this.formTemplateConfigurations.find(
          formTemplateConfiguration => formTemplateConfiguration.id === +this.$route.params.configurationId
        )
      }
    }
  },
  watch: {
    isFormTemplateNameUpdated: {
      immediate: false,
      handler  : function(newValue) {
        if (newValue) {
          this.setBreadcrumbs(
            ISSUE_FORM({ params: { id: this.localIssueForm.id } }, this.localIssueForm.name
            )
          )
          this.setPageTitle(PAGE_TITLE_WITHOUT_TRANSLATION(this.localIssueForm.name))
        }
      }
    },
    issueForm: {
      immediate: true,
      handler  : function(newValue){
        if(!this.localIssueForm){
          this.localIssueForm = { ...newValue }
        }
      }
    },
    issueFormTemplateConfigurationsAdded: {
      handler: function(value) {
        if (value) {
          this.notify({
            type      : "success",
            text      : "984",
            parameters: {
              issueFormTemplateName: this.issueForm.name
            }
          })
          this.showAddIssueFormFieldsDialog = false
        }
      }
    },
    showAddIssueFormFieldsDialog: {
      handler: function(value) {
        if (!value) {
          this.fieldsToBeAdded = []
        }
      }
    },
    isSortingOrderUpdated: {
      immediate: false,
      handler  : function(newValue) {
        if (newValue) {
          this.notify({
            type      : "success",
            text      : "917",
            parameters: {
              fieldSystemName: this.draggingFormTemplateField.fieldSystemName
            }
          })
          this.draggingFormTemplateField  = undefined
          this.dragEnterFormTemplateField = undefined
        }
      }
    },
    "localIssueForm.name": {
      immediate: false,
      handler  : function() {
        if (this.issueFormNameUpdateError) {
          this.resetIssueFormUpdateError(["name"])
        }
      }
    }
  }
}
